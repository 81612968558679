import React from 'react';
import { Flex, InputRadio } from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import { NotificationEdit, RECEIVE_TYPE_LABELS } from '~/models/Notification';
import Styles from '../NotificationModal.module.scss';

export const ReceiveTypeRadio: React.FC = () => {
  const { register, formState } = useFormContext<NotificationEdit>();
  const { errors } = formState;

  return (
    <>
      <Flex>
        <InputRadio
          isInvalid={errors.receiveType != null}
          label={RECEIVE_TYPE_LABELS.all}
          value="all"
          {...register('receiveType')}
        />
        <InputRadio
          isInvalid={errors.receiveType != null}
          label={RECEIVE_TYPE_LABELS.origin_deal_vendor_company}
          value="origin_deal_vendor_company"
          {...register('receiveType')}
        />
        <InputRadio
          isInvalid={errors.receiveType != null}
          label={RECEIVE_TYPE_LABELS.sub_deal_vendor_company}
          value="sub_deal_vendor_company"
          {...register('receiveType')}
        />
      </Flex>
      {errors.receiveType != null && (
        <div className={Styles.InvalidFeedback}>
          {errors.receiveType.message}
        </div>
      )}
    </>
  );
};
