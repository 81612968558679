import React from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modalv2
} from '@itandi/itandi-bb-ui';
import { RiCalendarEventLine } from 'react-icons/ri';
import { Deal } from '~/models/Deal';
import { NskResponse } from '~/models/Api';
import { isSuccess } from '~/utils/api/api_handler';
import {
  buildInitialSchedules,
  DealConstructionScheduleEdit,
  isInvalidSchedule
} from '~/models/DealConstructionSchedule';
import { ScheduleInputForm } from '~/components/common/construction_schedule/ScheduleInputForm';

type Props = {
  deal: Deal;
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (
    schedules: DealConstructionScheduleEdit[]
  ) => Promise<NskResponse<void>>;
  handleMutate: () => Promise<void>;
};

export const ScheduleInputModal: React.FC<Props> = ({
  deal,
  isOpen,
  handleClose,
  handleSubmit,
  handleMutate
}: Props) => {
  const [schedules, setSchedules] = React.useState<
    DealConstructionScheduleEdit[]
  >(buildInitialSchedules(deal));
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [touchSubmit, setTouchSubmit] = React.useState<boolean>(false);
  const onSubmitSchedule = async (): Promise<void> => {
    setTouchSubmit(true);
    if (schedules.some((s) => isInvalidSchedule(s))) {
      // invalid
      return;
    }
    setSubmitting(true);
    const filteredSchedules = schedules.filter((s) => s.startDate !== null);
    const result = await handleSubmit(filteredSchedules);
    if (isSuccess(result)) {
      await handleMutate();
      handleClose();
      toast.success('登録しました');
      setSubmitting(false);
      return;
    }
    setSubmitting(false);
    toast.error(`エラーが発生しました。${result.message}`);
  };
  const handleChange = (newSchedules: DealConstructionScheduleEdit[]): void => {
    setSchedules(newSchedules);
  };
  return (
    <Modalv2 handleClose={handleClose} isOpen={isOpen}>
      <ModalHeader icon={RiCalendarEventLine} title="工事日程登録" />
      <ModalBody>
        <ScheduleInputForm
          handleChange={handleChange}
          schedules={schedules}
          touchSubmit={touchSubmit}
        />
      </ModalBody>
      <ModalFooter
        leftArea={
          <Button onClick={handleClose} variant="secondary" width="fill">
            キャンセル
          </Button>
        }
        rightArea={
          <Button
            disabled={submitting}
            onClick={onSubmitSchedule}
            variant="primary"
            width="fill"
          >
            確定
          </Button>
        }
      />
    </Modalv2>
  );
};
