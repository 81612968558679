import React from 'react';
import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modalv2
} from '@itandi/itandi-bb-ui';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RiAddBoxLine, RiEdit2Line } from 'react-icons/ri';
import { NotificationModalForm } from '~/components/common/notifications/settings/NotificationModalForm';
import { Status } from '~/models/Status';
import {
  buildInitialNotification,
  Notification,
  NotificationEdit,
  validationSchema
} from '~/models/Notification';
import { Subtype } from '~/models/Subtype';

type Props = DeepReadonly<{
  type: 'CompanyNotification' | 'VendorNotification';
  isOpen: boolean;
  handleClose: () => void;
  statuses: Status[];
  subtypes: Subtype[] | null;
  notification?: Notification | null;
  handleAcceptSubmit: (notification: NotificationEdit) => Promise<void>;
}>;

export const NotificationModal: React.FC<Props> = ({
  type,
  isOpen,
  handleClose,
  statuses,
  subtypes,
  notification = null,
  handleAcceptSubmit
}: Props) => {
  const methods = useForm<NotificationEdit>({
    defaultValues: notification ?? buildInitialNotification(),
    mode: 'onBlur',
    resolver: yupResolver(validationSchema)
  });
  const handleSubmit = React.useCallback(() => {
    methods.handleSubmit(
      async () => {
        handleAcceptSubmit(methods.getValues()).then();
      },
      () => {
        const errorValues = Object.values(methods.formState.errors);
        if (errorValues.length > 0) {
          const inputRef: HTMLInputElement | null =
            errorValues[0]?.ref instanceof HTMLInputElement
              ? errorValues[0].ref
              : null;
          if (inputRef !== null) {
            inputRef.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }
    )();
  }, [methods, handleAcceptSubmit]);
  return (
    <Modalv2 handleClose={handleClose} isOpen={isOpen} size="Large">
      <ModalHeader
        icon={notification ? RiEdit2Line : RiAddBoxLine}
        title="通知設定"
      />
      <ModalBody>
        <FormProvider {...methods}>
          <NotificationModalForm
            statuses={statuses}
            subtypes={subtypes}
            type={type}
          />
        </FormProvider>
      </ModalBody>
      <ModalFooter
        leftArea={
          <Button onClick={handleClose} variant="secondary">
            保存せず閉じる
          </Button>
        }
        rightArea={
          <Button onClick={handleSubmit} variant="primary">
            {notification ? '更新' : '追加'}
          </Button>
        }
      />
    </Modalv2>
  );
};
