import React from 'react';
import { Flex, InputCheckBox } from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import {
  NotificationEdit,
  NotificationMail,
  AddressType
} from '~/models/Notification';
import Styles from '../NotificationModal.module.scss';

export const NotificationMailsCheckBox: React.FC = () => {
  const { watch, formState, setValue } = useFormContext<NotificationEdit>();
  const { errors } = formState;
  const notificationMails = watch('notificationMails');
  const onChangeCheckBox = React.useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      addressType: AddressType
    ): void => {
      const { checked } = e.target;
      if (checked) {
        setValue('notificationMails', [...notificationMails, { addressType }]);
      } else {
        setValue('notificationMails', [
          ...notificationMails.filter(
            (notificationMail) => notificationMail.addressType !== addressType
          )
        ]);
      }
    },
    [notificationMails, setValue]
  );
  const findNotificationMail = (
    addressType: AddressType
  ): DeepReadonlyObject<NotificationMail> | null =>
    notificationMails.find(
      (notificationMail) => notificationMail.addressType === addressType
    ) ?? null;

  return (
    <>
      <Flex>
        <InputCheckBox
          checked={!!findNotificationMail('deal_staff')}
          isInvalid={errors.notificationMails != null}
          labelText="案件担当者"
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            onChangeCheckBox(e, 'deal_staff')
          }
        />
        <InputCheckBox
          checked={!!findNotificationMail('store')}
          isInvalid={errors.notificationMails != null}
          labelText="店舗通知先"
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            onChangeCheckBox(e, 'store')
          }
        />
      </Flex>
      {errors.notificationMails != null && (
        <div className={Styles.InvalidFeedback}>
          {errors.notificationMails.message}
        </div>
      )}
    </>
  );
};
