import { array, number, object, string } from 'yup';

export type TriggerType =
  | 'status'
  | 'specific_date'
  | 'register'
  | 'order'
  | 'cancel'
  | 'sent_message'
  | 'deal_update'
  | 'assign_partner'
  | 'status_back'
  | 'complete_estimation'
  | 'complete_inspection_reports'
  | 'complete_completion_reports'
  | 'cancel_back'
  | 'cancel_rejected'
  | 'unassign_origin_vendor'
  | 'canceled_deal_reassign';
type TriggerDateType =
  | 'inspection_date'
  | 'construction_start_date'
  | 'construction_end_date';
type NotifyType = 'immediate' | 'reservation';
type SendType = 'mail' | 'slack';
export type AddressType = 'deal_staff' | 'store';
type DealType = 'all' | 'restoration' | 'repair' | 'other';
export type NotificationDealStateFormName =
  | 'dealEstimationState'
  | 'dealInspectionReportState'
  | 'dealCompletionReportState'
  | 'dealConstructionScheduleState';
export type NotificationDealState = 'no_setting' | 'yet' | 'registered';
export type ReceiveType =
  | 'all'
  | 'origin_deal_vendor_company'
  | 'sub_deal_vendor_company';
export type Notification = {
  id: number;
  dealType: DealType;
  dealSubtypeId: number | null;
  triggerType: TriggerType;
  triggerStatusId: number | null;
  triggerDateType: TriggerDateType | null;
  notifyType: NotifyType;
  notifyReservationDays: number | null;
  resend: boolean;
  sendType: SendType;
  dealEstimationState: NotificationDealState;
  dealInspectionReportState: NotificationDealState;
  dealCompletionReportState: NotificationDealState;
  dealConstructionScheduleState: NotificationDealState;
  slackWebhookUrl: string | null;
  slackUserName: string | null;
  slackIconEmoji: string | null;
  title: string | null;
  content: string | null;
  sendPartner: boolean;
  receiveType: ReceiveType;
  notificationMails: DeepReadonlyArray<NotificationMail>;
};

export type NotificationEdit = Omit<Notification, 'id' | 'triggerType'> & {
  triggerType: TriggerType | null;
};

export type NotificationMail = {
  id?: number;
  addressType: AddressType;
};

export const buildInitialNotification = (): NotificationEdit => ({
  dealType: 'all',
  dealSubtypeId: null,
  triggerType: null,
  triggerStatusId: null,
  triggerDateType: null,
  notifyType: 'immediate',
  notifyReservationDays: null,
  resend: false,
  sendType: 'mail',
  dealEstimationState: 'no_setting',
  dealInspectionReportState: 'no_setting',
  dealCompletionReportState: 'no_setting',
  dealConstructionScheduleState: 'no_setting',
  slackWebhookUrl: '',
  slackUserName: '',
  slackIconEmoji: '',
  title: '',
  content: '',
  sendPartner: false,
  receiveType: 'all',
  notificationMails: [{ addressType: 'deal_staff' }, { addressType: 'store' }]
});

export const validationSchema = object().shape({
  triggerType: string().nullable().required('入力してください'),
  triggerStatusId: number()
    .nullable()
    .when('triggerType', {
      is: 'status',
      then: number().nullable().required('入力してください')
    }),
  triggerDateType: string()
    .nullable()
    .when('triggerType', {
      is: 'specific_date',
      then: string().nullable().required('入力してください')
    }),
  notifyType: string().nullable().required('入力してください'),
  notifyReservationDays: number()
    .nullable()
    .typeError('数値で入力してください')
    .when('notifyType', {
      is: 'reservation',
      then: number()
        .nullable()
        .required('入力してください')
        .when('triggerType', {
          is: 'specific_date',
          otherwise: number().nullable().min(1, '1以上の数値を入力してください')
        })
    }),
  sendType: string().nullable().required('入力してください'),
  notificationMails: array().when('sendType', {
    is: 'mail',
    then: array().min(1, '1つ以上選択してください')
  }),
  slackWebhookUrl: string()
    .nullable()
    .when('sendType', {
      is: 'slack',
      then: string().nullable().required('入力してください')
    }),
  slackUserName: string()
    .nullable()
    .when('sendType', {
      is: 'slack',
      then: string().nullable().required('入力してください')
    }),
  slackIconEmoji: string()
    .nullable()
    .when('sendType', {
      is: 'slack',
      then: string().nullable().required('入力してください')
    }),
  dealType: string().nullable().required('入力してください'),
  dealSubtypeId: number()
    .nullable()
    .when('dealType', {
      is: 'other',
      then: number()
        .nullable()
        .when('type', {
          is: 'CompanyNotification',
          then: number().required('入力してください')
        })
    }),
  receiveType: string().nullable().required('入力してください')
});
export const DEAL_TYPE_LABELS = {
  all: 'すべて',
  restoration: '原状回復',
  repair: '修繕',
  other: 'その他'
} as const satisfies {
  [field in DealType]: string;
};

export const COMPANY_TRIGGER_TYPE_SELECT_OPTIONS: {
  [key: string]: { value: string; label: string };
} = {
  status: { value: 'status', label: 'ステータス進行' },
  sent_message: { value: 'sent_message', label: 'チャット投稿' },
  register: { value: 'register', label: '案件登録' },
  order: { value: 'order', label: '施工会社選択' },
  specific_date: { value: 'specific_date', label: '特定日付入力・指定' },
  cancel: { value: 'cancel', label: '取り下げ申請' },
  cancel_rejected: { value: 'cancel_rejected', label: '取り下げ申請差し戻し' }
};

export const VENDOR_TRIGGER_TYPE_SELECT_OPTIONS: {
  [key: string]: { value: string; label: string };
} = {
  status: { value: 'status', label: 'ステータス進行' },
  status_back: { value: 'status_back', label: 'ステータス戻し' },
  deal_update: { value: 'deal_update', label: '案件情報更新' },
  sent_message: { value: 'sent_message', label: 'チャット投稿' },
  order: { value: 'order', label: '施工会社選択' },
  assign_partner: { value: 'assign_partner', label: '実施会社選択' },
  specific_date: { value: 'specific_date', label: '特定日付入力・指定' },
  complete_estimation: { value: 'complete_estimation', label: '見積入力完了' },
  complete_inspection_reports: {
    value: 'complete_inspection_reports',
    label: '立会画像登録完了'
  },
  complete_completion_reports: {
    value: 'complete_completion_reports',
    label: '完了画像登録完了'
  },
  unassign_origin_vendor: {
    value: 'unassign_origin_vendor',
    label: '施工会社選択解除'
  },
  cancel: { value: 'cancel', label: '取り下げ申請' },
  cancel_back: { value: 'cancel_back', label: '取り下げ申請キャンセル' },
  canceled_deal_reassign: {
    value: 'canceled_deal_reassign',
    label: '取り下げ済み案件再依頼'
  }
};
export const TRIGGER_DATE_TYPE_LABELS = {
  inspection_date: '立会日',
  construction_start_date: '工事開始日',
  construction_end_date: '工事完了日'
} as const satisfies {
  [field in TriggerDateType]: string;
};

export const NOTIFY_TYPE_LABELS = {
  immediate: '即時通知',
  reservation: '通知予約'
} as const satisfies {
  [field in NotifyType]: string;
};

export const SEND_TYPE_LABELS = {
  mail: 'メール',
  slack: 'Slack'
} as const satisfies {
  [field in SendType]: string;
};

export const ADDRESS_TYPE_TO_LABELS = {
  deal_staff: '案件担当者',
  store: '店舗通知先'
} as const satisfies {
  [field in AddressType]: string;
};

export const DEAL_STATE_NAMES = {
  dealEstimationState: '見積り',
  dealInspectionReportState: '立会報告画像',
  dealCompletionReportState: '完了報告画像',
  dealConstructionScheduleState: '工事日程の入力状況'
} as const satisfies {
  [field in NotificationDealStateFormName]: string;
};

export const DEAL_STATE_LABELS = {
  no_setting: '登録状況に関わらず通知',
  yet: '未登録のみ',
  registered: '登録済みのみ'
} as const satisfies {
  [field in NotificationDealState]: string;
};

export const RECEIVE_TYPE_LABELS = {
  all: 'すべての案件',
  origin_deal_vendor_company: '施工会社のときのみ',
  sub_deal_vendor_company: '実施会社のときのみ'
} as const satisfies {
  [field in ReceiveType]: string;
};

export const ESTIMATION_DESCRIPTIONS = {
  no_setting: '',
  registered: '見積りが1件以上登録されている場合に通知されます',
  yet: '見積りが登録されていない場合に通知されます'
} as const satisfies {
  [field in NotificationDealState]: string;
};

export const REPORT_DESCRIPTIONS = {
  no_setting: '',
  registered: '画像が1件以上登録されている場合に通知されます',
  yet: '画像が登録されていない場合に通知されます'
} as const satisfies {
  [field in NotificationDealState]: string;
};

export const SCHEDULE_DESCRIPTIONS = {
  no_setting: '',
  registered: 'いずれかの日程が登録されている場合に通知されます',
  yet: '日程が一つも登録されていない場合に通知されます'
} as const satisfies {
  [field in NotificationDealState]: string;
};

export const ONLY_IMMEDIATE_TRIGGER_TYPE = [
  'sent_message',
  'deal_update',
  'assign_partner',
  'status_back',
  'complete_estimation',
  'complete_inspection_reports',
  'complete_completion_reports',
  'cancel_back',
  'cancel_rejected',
  'unassign_origin_vendor',
  'canceled_deal_reassign'
];
